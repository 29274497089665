export const TITLE = "Genera Password"
export const DESCRIPTION = "Utilizzate questo strumento per creare istantaneamente password sicure e casuali."
export const COPIED = "Copiato"
export const COPY_PASSWORD = "Copia password"
export const ADVANCED = "AVANZATE"
export const PASSWORD_LENGTH = "Lunghezza password"
export const LOWERCASE = "Minuscolo"
export const UPPERCASE = "Maiuscolo"
export const NUMBERS = "Numeri"
export const SYMBOLS = "Simboli"
export const WORDS = "Basata sulle parole"
export const WORDS_TITLE = "Genera parole sillabate come password"
export const EXCLUDE_CHARACTERS = "Escludere i caratteri somiglianti"
export const EXCLUDE_CHARACTERS_TITLE = "Escludere i caratteri"
export const DO_NOT_INCLUDE = "Non includere"
export const DO_NOT_INCLUDE_PLACEHOLDER = "Caratteri personalizzati da escludere"
export const GENERATE_PASSWORD = "Genera password"
export const NO_OPTION_ERROR_TEXT = "Selezionare almeno un'opzione"
export const SYMBOL_ONLY_ERROR_TEXT = "Non è possibile selezionare solo l'opzione simbolo"
export const FAQ_TITLE = "Domande frequenti"
export const FAQ = [
    {
        "question": "Che cos'è un generatore di password?",
        "answer": "Un generatore di password è uno strumento utile per creare password sicure e uniche. Grazie a questi strumenti, è possibile ottenere combinazioni di caratteri casuali e personalizzabili, che migliorano la sicurezza dei tuoi account. Le password generate sono spesso progettate per soddisfare standard di sicurezza avanzati, rendendole difficili da indovinare o forzare. Per ottenere una password forte, è consigliabile utilizzare generatori affidabili con opzioni di personalizzazione."
    },
    {
        "question": "Questo generatore è sicuro da usare?",
        "answer": "Sì, un buon generatore di password non memorizza o condivide le password create. Le migliori piattaforme eseguono la generazione localmente sul tuo dispositivo, assicurandosi che le password non siano inviate a server esterni. Ricorda di non lasciare la pagina incustodita e di copiare subito la password generata. È anche importante verificare che il sito utilizzato abbia connessioni sicure (HTTPS)."
    },
    {
        "question": "Quali sono i requisiti per una password forte?",
        "answer": "Una <a href=\"https://www.pcmag.com/encyclopedia/term/strong-password\" target=\"_blank\">password forte</a> è caratterizzata da un alto livello di complessità e casualità. Deve includere: \n- Almeno 12 caratteri. \n- Una combinazione di lettere maiuscole e minuscole. \n- Numeri e simboli speciali. \nUna password sicura evita l’utilizzo di parole comuni, sequenze prevedibili e informazioni personali."
    },
    {
        "question": "Quali sono le password da non utilizzare?",
        "answer": "È essenziale evitare password deboli o prevedibili. Eccone alcuni esempi da evitare: \n- Sequenze semplici come \"123456\", \"qwerty\" o \"abcdef\". \n- Parole comuni come \"password\", \"admin\" o \"welcome\". \n- Informazioni personali: nomi, date di nascita o numeri telefonici. \n- Password già utilizzate in passato. \nUna password sicura è unica e non facilmente associabile a te o ai tuoi interessi personali."
    },
    {
        "question": "Quanto deve essere lunga la mia password?",
        "answer": "La lunghezza è un fattore determinante per la sicurezza di una password. Una password deve essere lunga almeno 12 caratteri, ma è consigliabile puntare a 16 o più caratteri per una protezione superiore. Una lunghezza maggiore aumenta la complessità e rende più difficile per gli hacker utilizzare tecniche di brute force."
    },
    {
        "question": "Ho bisogno di una password unica per ogni account?",
        "answer": "Assolutamente sì. Utilizzare la stessa password per account diversi è estremamente rischioso. Se un account viene compromesso, tutti gli altri che condividono la stessa password saranno a rischio. Generare password uniche per ogni account è una delle migliori pratiche di sicurezza."
    },
    {
        "question": "Una password forte può essere violata?",
        "answer": "Anche una password forte può essere vulnerabile se non segui alcune buone pratiche. Ecco alcuni consigli per ridurre il rischio: \n1. Non riutilizzare password. \n2. Evita schemi prevedibili, come lettere consecutive o simboli ripetuti. \n3. Cambia regolarmente le password importanti. \n4. Usa un generatore di password affidabile per assicurarti che siano casuali e complesse."
    },
    {
        "question": "Come posso memorizzare password complesse?",
        "answer": "Utilizzare un gestore di password è il modo più semplice e sicuro per gestire credenziali complesse. Questi strumenti memorizzano le password in un archivio cifrato, consentendoti di accedervi facilmente. Un buon gestore di password ti permette anche di generare password sicure direttamente al suo interno."
    },
    {
        "question": "Come funziona un generatore di password?",
        "answer": "Un generatore di password utilizza algoritmi per creare stringhe di caratteri casuali. Questi strumenti ti consentono di: \n- Scegliere la lunghezza desiderata. \n- Selezionare il tipo di caratteri da includere (lettere, numeri, simboli). \n- Evitare combinazioni simili o difficili da distinguere, come \"0\" e \"O\". \nUn generatore di password è la soluzione ideale per creare password forti e difficili da violare."
    },
    {
        "question": "Perché è importante avere una password sicura?",
        "answer": "Una password sicura protegge i tuoi account da accessi non autorizzati. Con la crescente sofisticazione degli attacchi informatici, è fondamentale adottare misure preventive. Le password forti riducono il rischio di: \n- Furti di dati personali. \n- Compromissione di account bancari. \n- Accesso non autorizzato a email e social media."
    },
    {
        "question": "Posso creare una password sicura senza un generatore?",
        "answer": "Sì, ma richiede maggiore attenzione. Ecco alcune linee guida per creare manualmente una password sicura: \n1. Usa una combinazione di almeno 12 caratteri, includendo lettere, numeri e simboli. \n2. Evita parole o frasi comuni. \n3. Considera l’uso di una passphrase, ovvero una sequenza di parole casuali combinate con simboli. \nTuttavia, utilizzare un generatore di password è spesso più veloce e sicuro."
    }
]

