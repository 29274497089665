import React from "react";
import ReactDOM from "react-dom/client";
import "./components/styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";


//const ga4react = new GA4React(config.GA);

(async (_) => {

      const root = ReactDOM.createRoot(document.getElementById("root"));

      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );

})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
