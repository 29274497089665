import React from "react";
import copy from "./components/img/copy.svg";
import logo_black from "./components/img/logo_black.svg";
import logo_light from "./components/img/logo_light.svg";
import "./components/styles/style.css";
import { config } from "./utils/config/default";

import {
  TITLE,
  DESCRIPTION,
  COPIED,
  COPY_PASSWORD,
  ADVANCED,
  PASSWORD_LENGTH,
  LOWERCASE,
  UPPERCASE,
  NUMBERS,
  SYMBOLS,
  EXCLUDE_CHARACTERS,
  EXCLUDE_CHARACTERS_TITLE,
  DO_NOT_INCLUDE,
  DO_NOT_INCLUDE_PLACEHOLDER,
  GENERATE_PASSWORD,
  NO_OPTION_ERROR_TEXT,
  SYMBOL_ONLY_ERROR_TEXT,
  FAQ,
  FAQ_TITLE,
  WORDS,
  WORDS_TITLE,
} from "./utils/lang/ITA";
//import WordList from "./components/WordList";
//import { LANGUAGES } from "./utils/lang/index";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      lowercaseCheckBox: true,
      uppercaseCheckBox: true,
      numbersCheckBox: true,
      symbolsCheckBox: true,
      advanceSettingsCheckBox: false,
      similarCharactersCheckBox: false,
      wordsCheckBox: false,
      doNotInclude: "",
      lang: localStorage.getItem("site-lang") || "ITA",
      //theme: localStorage.getItem("site-theme") || "light",
      title: TITLE,
      description: DESCRIPTION,
      copied: COPIED,
      copy_password: COPY_PASSWORD,
      advanced: ADVANCED,
      password_length: PASSWORD_LENGTH,
      lowercase: LOWERCASE,
      uppercase: UPPERCASE,
      numbers: NUMBERS,
      symbols: SYMBOLS,
      words: WORDS,
      words_title: WORDS_TITLE,
      exclude_characters: EXCLUDE_CHARACTERS,
      exclude_characters_title: EXCLUDE_CHARACTERS_TITLE,
      do_not_include: DO_NOT_INCLUDE,
      do_not_include_placeholder: DO_NOT_INCLUDE_PLACEHOLDER,
      generate_password: GENERATE_PASSWORD,
      no_option_error_text: NO_OPTION_ERROR_TEXT,
      symbol_only_error_text: SYMBOL_ONLY_ERROR_TEXT,
      faq: FAQ,
      faq_title: FAQ_TITLE,
    };
  }

  componentDidMount() {
    const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const theme = localStorage.getItem("site-theme") || (systemPrefersDark ? "dark" : "light");


    this.setState({ theme }, this.applyTheme);

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
      const newTheme = e.matches ? "dark" : "light";

      localStorage.setItem("site-theme", newTheme);
      this.setState({ theme: newTheme }, this.applyTheme);
    });
  }

  applyTheme() {
    const { theme } = this.state;
    document.body.className = theme;

    const logo = theme === "dark" ? logo_black : logo_light;
    this.setState({ logo });
  }


  // Set password strength meter
  setPasswordStrength(passwordLength) {
    const passwordStrength = document.querySelector(".password_strength");

    const weakPasswordMaxLength = 9;
    const semiWeakPasswordMaxLength = 20;
    const goodPasswordMaxLength = 40;

    if (passwordLength <= weakPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-danger-dark)";
      passwordStrength.style.width = "15%";
    } else if (passwordLength <= semiWeakPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-warning-dark)";
      passwordStrength.style.width = "45%";
    } else if (passwordLength <= goodPasswordMaxLength) {
      passwordStrength.style.backgroundColor = "var(--color-success)";
      passwordStrength.style.width = "75%";
    } else {
      passwordStrength.style.backgroundColor = "var(--color-success-dark)";
      passwordStrength.style.width = "100%";
    }
  }

  // Display password length value to the user.
  passwordLength(e) {
    let lengthDisplay = document.querySelector(".length-display");

    lengthDisplay.innerText = e.target.value;
    this.setPasswordStrength(e.target.value);

    this.generatePassword();

    return;
  }

  alphabets() {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }

  numberList() {
    return "0123456789";
  }

  symbolList() {
    return "!@#$%_-<>?";
  }

  /*wordsList() {
    return WordList();
  }*/

  generatePassword(e) {
    let lowerletters;
    let upperletters;
    let numbers;
    let symbols;
    let words;
    let generatedPassword = "";

    e?.preventDefault();

    // Return an error to the user if no option is selected.
    if (
        !this.lowercase.checked &&
        !this.uppercase.checked &&
        !this.numbers.checked &&
        !this.symbols.checked &&
        !this.words.checked
    ) {
      let feedbackElement = document.querySelector(".error-wrapper");

      feedbackElement.style.display = "block";
      feedbackElement.firstChild.innerText = this.state.no_option_error_text;

      setTimeout(() => {
        feedbackElement.style.display = "";
      }, 3000);

      return;
    }

    // Return an error to the user if only symbol option is selected.
    if (
        !this.lowercase.checked &&
        !this.uppercase.checked &&
        !this.numbers.checked &&
        !this.words.checked &&
        this.symbols.checked
    ) {
      let feedbackElement = document.querySelector(".error-wrapper");

      feedbackElement.style.display = "block";
      feedbackElement.firstChild.innerText = this.state.symbol_only_error_text;

      setTimeout(() => {
        feedbackElement.style.display = "";
      }, 3000);

      return;
    }

    // Add lowercase letters to generatedPassword if the option is checked.
    if (this.lowercase.checked) {
      //shuffle letters and reduce the length to the password length the user choosed
      lowerletters = this.alphabets()
          .repeat(4)
          .toLowerCase()
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .slice(0, this.length.value);
      generatedPassword += lowerletters;
    }

    // Add uppercase letters to generatedPassword if the option is checked.
    if (this.uppercase.checked) {
      // shuffle letters and reduce the length to the password length the user selected.
      upperletters = this.alphabets()
          .repeat(4)
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .slice(0, this.length.value);
      generatedPassword += upperletters;
    }
    // Add numbers to generatedPassword if the option is checked.
    if (this.numbers.checked) {
      //shuffle numbers and reduce the length to the password length the user selected.
      numbers = this.numberList()
          .repeat(10)
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .slice(0, this.length.value);
      generatedPassword += numbers;
    }
    // Add symbols letters to generatedPassword if the option is checked.
    if (this.symbols.checked) {
      //shuffle symbols and reduce the length to the password length the user selected.
      symbols = this.symbolList()
          .repeat(4)
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .slice(0, this.length.value);
      generatedPassword += symbols;
    }

    // Get random words and hyphenate it if the option is checked.
    if (this.words.checked) {
      //shuffle words and reduce the length to the password length the user selected.
      words = this.wordsList()
          .split(" ")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .splice(0, this.length.value)
          .join("-");
      generatedPassword = words;
    }

    // Exclude 0, 1, I, i, O, ., |, `, ' from the generated password if the option is checked
    if (this.similarCharacters.checked) {
      generatedPassword = generatedPassword
          .repeat(4)
          .replace(/0|1|I|i|O|\.|\||`|'/gi, "");
    }

    // Exclude custom characters from the generated password if the exclude custom characters field is not empty
    if (this.state.doNotInclude) {
      let excludeCharacters = this.state.doNotInclude.split("");

      // Escape the characters to be excluded to prevent regex errors
      excludeCharacters = excludeCharacters
          .map((character) => {
            // eslint-disable-next-line no-useless-escape
            return character.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
          })
          .join("|");

      generatedPassword = generatedPassword
          .repeat(4)
          .replace(new RegExp(excludeCharacters, "ig"), "");
    }

    if (!this.words.checked) {
      // Shuffle final password and reduce the length to the password length the user selected.
      generatedPassword = generatedPassword
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("")
          .slice(0, this.length.value);
    }

    // Ensure the password does not start or end with a special character
    const specialChars = this.symbolList();
    if (specialChars.includes(generatedPassword[0])) {
      generatedPassword = 'A' + generatedPassword.slice(1);
    }
    if (specialChars.includes(generatedPassword[generatedPassword.length - 1])) {
      generatedPassword = generatedPassword.slice(0, -1) + 'Z';
    }

    // Set value of the text field to the generated password.
    this.passfield.value = generatedPassword;

    return;
  }

  //function to copy password from the textfield
  copyPassword() {
    let passfield = document.querySelector(".passfield");
    let feedbackEle = document.querySelector(".copy-feedback");

    /* Select the text field */
    passfield.select();
    passfield.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    // Only display feedback when passfield contains a value.
    if (passfield.value) {
      feedbackEle.style.top = "22px";
      setTimeout(() => {
        feedbackEle.style.top = "";
      }, 2000);
    }

    return;
  }

  // show custom checkbox if its corresponding input checkbox is checked
  lowercaseState = () => {
    this.words.checked = false;

    return this.setState({
      lowercaseCheckBox: !this.state.lowercaseCheckBox,
      wordsCheckBox: false,
    });
  };
  uppercaseState = () => {
    this.words.checked = false;

    return this.setState({
      uppercaseCheckBox: !this.state.uppercaseCheckBox,
      wordsCheckBox: false,
    });
  };
  numbersState = () => {
    this.words.checked = false;

    return this.setState({
      numbersCheckBox: !this.state.numbersCheckBox,
      wordsCheckBox: false,
    });
  };
  symbolsState = () => {
    this.words.checked = false;

    return this.setState({
      symbolsCheckBox: !this.state.symbolsCheckBox,
      wordsCheckBox: false,
    });
  };
  wordsState = () => {
    this.numbers.checked = false;
    this.symbols.checked = false;
    this.uppercase.checked = false;
    this.lowercase.checked = false;
    this.words.checked = !this.state.wordsCheckBox;
    this.similarCharacters.checked = false;

    return this.setState({
      lowercaseCheckBox: false,
      uppercaseCheckBox: false,
      numbersCheckBox: false,
      symbolsCheckBox: false,
      similarCharactersCheckBox: false,
      wordsCheckBox: !this.state.wordsCheckBox,
    });
  };
  similarCharactersState = () => {
    return this.setState({
      similarCharactersCheckBox: !this.state.similarCharactersCheckBox,
      wordsCheckBox: false,
    });
  };
  doNotIncludeState = (e) => {
    return this.setState({
      doNotInclude: e.target.value,
    });
  };
  advanceSettingsState = () => {
    this.setState({
      advanceSettingsCheckBox: !this.state.advanceSettingsCheckBox,
    });
    if (!this.state.advanceSettingsCheckBox === false) {
      this.setState({
        similarCharactersCheckBox: false,
        doNotInclude: "",
        wordsCheckBox: false,
      });
    }
    return;
  };

  toggleSiteLanguages = (e) => {
    return e.target.parentElement.classList.toggle("active");
  };

  toggleSiteLanguagesAlt = (e) => {
    return e.target.classList.toggle("active");
  };

  setSiteLangOnLoad = () => {
    (async () => {
      this.setState({ lang: localStorage.getItem("site-lang") || "ITA" });
      //check theme and set it
      //this.setState({ theme: localStorage.getItem("site-theme") || "light" });
      let {
        TITLE,
        DESCRIPTION,
        COPIED,
        COPY_PASSWORD,
        ADVANCED,
        PASSWORD_LENGTH,
        LOWERCASE,
        UPPERCASE,
        NUMBERS,
        SYMBOLS,
        EXCLUDE_CHARACTERS,
        EXCLUDE_CHARACTERS_TITLE,
        DO_NOT_INCLUDE,
        DO_NOT_INCLUDE_PLACEHOLDER,
        GENERATE_PASSWORD,
        NO_OPTION_ERROR_TEXT,
        SYMBOL_ONLY_ERROR_TEXT,
        FAQ,
        FAQ_TITLE,
        WORDS,
        WORDS_TITLE,
      } = await import(`./utils/lang/${this.state.lang}`);

      this.setState({
        title: TITLE,
        description: DESCRIPTION,
        copied: COPIED,
        copy_password: COPY_PASSWORD,
        advanced: ADVANCED,
        password_length: PASSWORD_LENGTH,
        lowercase: LOWERCASE,
        uppercase: UPPERCASE,
        numbers: NUMBERS,
        symbols: SYMBOLS,
        words: WORDS,
        words_title: WORDS_TITLE,
        exclude_characters: EXCLUDE_CHARACTERS,
        exclude_characters_title: EXCLUDE_CHARACTERS_TITLE,
        do_not_include: DO_NOT_INCLUDE,
        do_not_include_placeholder: DO_NOT_INCLUDE_PLACEHOLDER,
        generate_password: GENERATE_PASSWORD,
        no_option_error_text: NO_OPTION_ERROR_TEXT,
        symbol_only_error_text: SYMBOL_ONLY_ERROR_TEXT,
        faq: FAQ,
        faq_title: FAQ_TITLE,
      });

      let lengthValue = document.querySelector(".password-length-range");
      this.setPasswordStrength(lengthValue.value);

      this.generatePassword();
    })();
  };



  changeSiteLanguage = (e) => {
    localStorage.setItem("site-lang", e.target.dataset.lang);
    window.location.reload();
    return;
  };

  render() {
    return (
      <div className="App" onLoad={this.setSiteLangOnLoad}>
        <div className="container">
          <div className="image-container">
            <img src={this.state.logo} alt="Logo" height={210}/>
          </div>
          <p>{this.state.description}</p>
          <div className="form-wrapper">
            <div className="form-inner-wrapper">
              <div className="copy-feedback">
                <p>{"**" + this.state.copied}</p>
              </div>
              <form
                method="post"
                action=""
                onSubmit={(e) => this.generatePassword(e)}
              >
                {this.state.wordsCheckBox ? (
                  <div className="form-top-section has-textarea">
                    <textarea
                      type="text"
                      name=""
                      className="passfield"
                      title="passfield"
                      ref={(input) => (this.passfield = input)}
                      readOnly
                    />
                    <div
                      className="copy-wrapper"
                      title={this.state.copy_password}
                      onClick={(e) => this.copyPassword()}
                    >
                      <img src={copy} alt="Copy Icon" />
                    </div>
                    <div className="password_strength"></div>
                  </div>
                ) : (
                  <div className="form-top-section">
                    <input
                      type="text"
                      name=""
                      className="passfield"
                      title="passfield"
                      ref={(input) => (this.passfield = input)}
                      readOnly
                    />
                    <div
                      className="copy-wrapper"
                      title={this.state.copy_password}
                      onClick={(e) => this.copyPassword()}
                    >
                      <img src={copy} alt="Copy Icon" />
                    </div>
                    <div className="password_strength"></div>
                  </div>
                )}
                <div className="advance-settings-wrapper">
                  <label
                    className={
                      this.state.advanceSettingsCheckBox ? "selected" : ""
                    }
                  >
                    <div className="switch">
                      <span className="switch-lever"></span>
                    </div>
                    <input
                      type="checkbox"
                      name=""
                      onChange={this.advanceSettingsState}
                      defaultChecked={this.state.advanceSettingsCheckBox}
                      ref={(input) => (this.advanceSettings = input)}
                    />
                    <span>{this.state.advanced}</span>
                  </label>
                </div>
                <div className="form-footer-section">
                  <div className="form-footer-main-column">
                    <label>{this.state.password_length}</label>
                    <input
                      type="range"
                      name=""
                      className="password-length-range"
                      title="password lenght"
                      onChange={(e) => this.passwordLength(e)}
                      defaultValue={config.defaultPasswordLength || 16}
                      ref={(input) => (this.length = input)}
                      min="6"
                      max="50"
                      step="1"
                    />
                    <div className="length-display">
                      {config.defaultPasswordLength || 16}
                    </div>
                  </div>
                  <div className="form-footer-main-column">
                    <div className="form-footer-column">
                      <label
                        className={
                          this.state.lowercaseCheckBox ? "selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.lowercaseState}
                          defaultChecked={this.state.lowercaseCheckBox}
                          ref={(input) => (this.lowercase = input)}
                        />
                        <span>{this.state.lowercase}</span>
                        <span className="check"></span>
                      </label>
                      <label
                        className={
                          this.state.uppercaseCheckBox ? "selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.uppercaseState}
                          defaultChecked={this.state.uppercaseCheckBox}
                          ref={(input) => (this.uppercase = input)}
                        />
                        <span>{this.state.uppercase}</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className="form-footer-column">
                      <label
                        className={this.state.numbersCheckBox ? "selected" : ""}
                      >
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.numbersState}
                          defaultChecked={this.state.numbersCheckBox}
                          ref={(input) => (this.numbers = input)}
                        />
                        <span>{this.state.numbers}</span>
                        <span className="check"></span>
                      </label>
                      <label
                        className={this.state.symbolsCheckBox ? "selected" : ""}
                      >
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.symbolsState}
                          defaultChecked={this.state.symbolsCheckBox}
                          ref={(input) => (this.symbols = input)}
                        />
                        <span>{this.state.symbols}</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div className="form-footer-column">
                      <label
                        className={this.state.wordsCheckBox ? "selected" : ""}
                        title={this.state.words_title}
                      >
                        <input
                          type="checkbox"
                          name=""
                          onChange={this.wordsState}
                          defaultChecked={this.state.wordsCheckBox}
                          ref={(input) => (this.words = input)}
                        />
                        <span>{this.state.words}</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div
                      className={
                        this.state.advanceSettingsCheckBox
                          ? "form-footer-column advance-option advance-active"
                          : "form-footer-column advance-option"
                      }
                    >
                      <label
                        className={
                          this.state.similarCharactersCheckBox ? "selected" : ""
                        }
                        title={
                          this.state.exclude_characters_title +
                          ": 0, 1, I, i, O, ., |, `, ' "
                        }
                      >
                        <input
                          type="checkbox"
                          name=""
                          autoComplete="new-password"
                          onChange={this.similarCharactersState}
                          defaultChecked={this.state.similarCharactersCheckBox}
                          ref={(input) => (this.similarCharacters = input)}
                        />
                        <span>{this.state.exclude_characters}</span>
                        <span className="check"></span>
                      </label>
                    </div>
                    <div
                      className={
                        this.state.advanceSettingsCheckBox
                          ? "form-footer-column advance-option advance-active"
                          : "form-footer-column advance-option"
                      }
                    >
                      <div>
                        <label htmlFor="doNotInclude">
                          {this.state.do_not_include + ":"}
                        </label>
                        <input
                          type="text"
                          name=""
                          id="doNotInclude"
                          placeholder={this.state.do_not_include_placeholder}
                          onChange={this.doNotIncludeState}
                          value={this.state.doNotInclude}
                          ref={(input) => (this.doNotInclude = input)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-button">
                  <input
                    type="submit"
                    className="generate-button"
                    value={this.state.generate_password}
                  />
                </div>
              </form>
              <div className="error-wrapper">
                <p></p>
              </div>
            </div>
          </div>
          <div className="extended_section">
            <div className="extended_section_inner">
              <div className="faq_wrapper">
                <h2>{this.state.faq_title}</h2>
                <ul
                  className="faq_list"
                  style={{
                    textAlign: this.state.lang === "ARA" ? "right" : "",
                  }}
                >
                  {this.state.faq.map((item, index) => {
                    return (
                      <li key={index} className="faq_item">
                        <h3 className="question">
                          <span>{item.question}</span>
                        </h3>
                        <div
                          className="answer"
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        ></div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
            <footer>
                <div className="footer-inner">
                <p className="footer">© 2010-2025 Tutti i diritti riservati | Open Plug Srl P.iva 02050680442</p>
                  </div>
            </footer>
        </div>
      </div>
    );
  }
}

export default App;
